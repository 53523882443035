import React from 'react'
import './styles.css'
import Badge from '@mui/material/Badge';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import useStore from '../../context/StoreContext'
import { StaticImage } from 'gatsby-plugin-image';
import { styled } from '@mui/material/styles';
import {Link} from 'gatsby'

export default function MobileLogoCart() {
const logo = '../../images/logo.png'

    const { cart } = useStore()

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            
            backgroundColor:"#000000"
            
        },
    }));

        
    return (
        <div className = 'mobile-logo-cart' >
            <Link to = '/' style = {{width:'55%', marginLeft:'22.5%'}}>
                <StaticImage src = {logo} style = {{width:'100%', height: 'auto'}} alt = 'Bender logo' />
            </Link>
            <div className = 'mlc-cart' style = {{width:'22.5%'}}>
                <Link to = '/cart' style = {{textDecoration:'none', color:'black', display:'flex',flexDirection:'row', justifyContent:'center', alignItems:'center'}}  >
                <StyledBadge badgeContent={cart?.length} color="primary">
                    <ShoppingCartOutlinedIcon/>
                </StyledBadge>
                </Link>
            </div>
            
        </div>
    )

}