import React from 'react'
import './Footer.css'
import MediaQuery from 'react-responsive'
import { StaticImage } from 'gatsby-plugin-image'
import {Link} from 'gatsby'
const  ig =  '../../images/static-imgs/instagram-icon.webp'
const  twitter =  '../../images/static-imgs/twitter-icon.webp'
const  tiktok =  '../../images/static-imgs/tik-tok-icon.webp'
const  facebook =  '../../images/static-imgs/facebook-icon.webp'
const  logo =  '../../images/logo.png'
const twitterLink = (
  <a href="https://twitter.com/thebenderdrink" alt="twitter link">
    Twitter
  </a>
)
const facebookLink = (
  <a href="https://facebook.com/TheBenderDrink" alt="facebook link">
    Facebook
  </a>
)
const emailLink = (
  <a href="mailto:support@bender.buzz" alt="email link">
    Email
  </a>
)

const Footer = () => (
  <div style ={{display:'flex', flexDirection:'column', backgroundImage:'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)'}} >
  <div className = 'footer-container' >
    <div className = 'footer-column'>
      <div className = 'footer-header'>Company</div>
      <Link to = '/terms-and-conditions' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'>Terms &amp; conditions</Link>
      <Link to = '/privacy-policy' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'>Privacy Policy</Link>
      <Link to = '/fda-disclaimer' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'>Disclaimer</Link>
    </div>
    <div className = 'footer-column'>
      <div className = 'footer-header'>Support</div>
      <Link to = '/shipping-policy' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'>Shipping</Link>
      <Link to = '/returns' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'>Returns &amp; Refunds</Link>
      <Link to = '/contact' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'>Contact Us</Link>
    </div>
    <div className = 'footer-column'>
      <div className = 'footer-header'>Social</div>
      <a href = 'https://www.instagram.com/thebenderdrink/' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'><StaticImage src = {ig} className = 'footer-social-icon' />Instagram</a>
      <a href = 'https://www.facebook.com/TheBenderDrink' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'><StaticImage src = {facebook} className = 'footer-social-icon' />Facebook</a>
      <a href = 'https://www.tiktok.com/@thebenderdrink' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'><StaticImage src = {tiktok} className = 'footer-social-icon' />TikTok</a>
      <a href = 'https://twitter.com/TheBenderDrink' style = {{textDecoration:'none', color:'black'}} className = 'footer-link'><StaticImage src = {twitter} className = 'footer-social-icon' />Twitter</a>
    </div>
    <MediaQuery minWidth = {601}>
      <div className = 'footer-logo'>
        <StaticImage src = {logo} className = 'footer-logo-image' alt = 'Bender Logo' />
      </div>
    </MediaQuery>
  </div>
  <MediaQuery maxWidth = {600}>
    <div className = 'footer-logo' style = {{width:'100%', display:'flex', alignItems:'center', padding:'5px'}}>
        <StaticImage src = {logo} className = 'footer-logo-image' alt = 'Bender Logo' />
      </div>
  </MediaQuery> 
  </div>
)

export default Footer
