import React from 'react';
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import useStore from '../../context/StoreContext'
import { useState, useEffect} from 'react'
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function ApplyUrlDiscount ({location}){
  const { checkout, addDiscount } = useStore()
  const params = new URLSearchParams(location?.search)
  const discount = params.get("discount")

  useEffect(() => {
    if (discount) {
      localStorage.setItem("urlDiscount", discount)
     
      setOpen(true)
    }
  }, [discount])

  useEffect(() => {
    if (discount) {
      applyDiscount(discount)
    }
  }, [checkout?.id])

  async function applyDiscount(discount){
    let discountAdded = await addDiscount(discount)
    console.log('dadded',discountAdded)
  }
  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

    return(

        <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} >
        <div>
          <Alert onClose={handleClose} severity="success" sx={{width: '100%', backgroundImage:'linear-gradient(60deg, #abecd6 0%, #fbed96 100%)', color:'black'}}>
            
            <div style = {{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
              <div >Discount code <strong>"{discount}"</strong> will be applied to your cart</div>
            </div>
          </Alert>
        </div>
      </Snackbar>

    )

}